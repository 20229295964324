import * as Yup from "yup";
import { isValidPhoneNumber, isValidNightPhoneNumber, isValidVAEmail } from "../../inputs/phoneInput/PhoneInput";

const contactInfoSchema = Yup.object()
  .shape({
    dayPhone: Yup.string()
      .default("")
      .required("Day phone is required.")
      .test(
        "valid length",
        "Phone numbers must be 10 digits.",
        isValidPhoneNumber
      ),
    nightPhone: Yup.string().default(""),
    email: Yup.string()
      .default("")
      .required("Email address is required.")
      .email("Email address must be valid."),
    primaryContactByEmail: Yup.string().default("AGREE")
  })
  .defined();

export const contactInfoSchemaDynamic = (questions) =>
  Yup.object()
    .shape({
      dayPhone: Yup.string()
        .default("")
        .when("check", {
          is: () => questions.includes("dayPhone"),
          then: Yup.string()
            .default("")
            .required("Day phone is required.")
            .test(
              "valid length",
              "Phone numbers must be 10 digits.",
              isValidPhoneNumber
            ),
          otherwise: Yup.string().default(""),
        }),
      nightPhone: Yup.string()
        .default("")
        .nullable()
        .optional()
        .test(
          "valid length",
          "Phone numbers must be 10 digits.",
          isValidNightPhoneNumber
        ),
      officePhone: Yup.string()
        .default("")
        .nullable()
        .optional()
        .test(
          "valid length",
          "Phone numbers must be 10 digits.",
          isValidNightPhoneNumber
        ),
      email: Yup.string()
        .default("")
        .when("check", {
          is: () => questions.includes("email"),
          then: Yup.string()
            .default("")
            .required("Email address is required.")
            .email("Email address must be valid."),
          otherwise: Yup.string().default(""),
        }),
      vaEmail: Yup.string()
        .default("")
        .nullable()
        .optional()
        .test(
          "valid email",
          "VA email address must be valid.",
          isValidVAEmail
        ),
        alternateEmail: Yup.string()
        .default("")
        .nullable()
        .optional(),
      primaryContactByEmail: Yup.string().default("AGREE")
    })
    .defined();

export default contactInfoSchema;
