import React from "react";
import Textbox from "../../inputs/textbox/Textbox";
import Spacer from "../spacer/Spacer";
import { Divider, Typography } from "@material-ui/core";
import PhoneInput from "../../inputs/phoneInput/PhoneInput";
import { CompProps } from "../../modules/onboardingForm/PageConfigs";
import { FormikSelect as Select } from "../../inputs/select/Select";

const buildInputName = (name: string): string => `contactInfo.${name}`;

const primaryContactByEmailOptions = [
  { label: "AGREE", value: "AGREE" },
  { label: "DO NOT AGREE", value: "DO NOT AGREE" }
]

const ContactInfo: React.FC<any> = ({ onChange, questions, sections }) => (
  <Spacer direction="column">
    <Typography variant="h5">Contact information</Typography>
    <Typography variant="subtitle1">
      Please enter up to date contact inforamtion.
    </Typography>
    {questions.includes("dayPhone") && (
      <PhoneInput
        required={true}
        name={buildInputName("dayPhone")}
        handleChange={onChange}
        label="Day phone"
      />
    )}
    {questions.includes("nightPhone") && (
      <PhoneInput
        name={buildInputName("nightPhone")}
        handleChange={onChange}
        label="Night phone"
      />
    )}
    {questions.includes("officePhone") && (
      <PhoneInput
        name={buildInputName("officePhone")}
        handleChange={onChange}
        label="Office phone"
      />
    )}
    {questions.includes("email") && (
      <Textbox
        required={true}
        name={buildInputName("email")}
        label="Aptive or Personal email address"
      />
    )}
    {questions.includes("vaEmail") && (
      <Textbox
        name={buildInputName("vaEmail")}
        label="VA email address"
      />
    )}
     {questions.includes("alternateEmail") && (
      <Textbox
        name={buildInputName("alternateEmail")}
        label="Alternate email address"
      />
    )}
    {questions.includes("primaryContactByEmail") && (
      <Select
        required={true}
        name={buildInputName("primaryContactByEmail")}
        label="Email as a primary form of communication"
        options={primaryContactByEmailOptions}
      />
    )}
    {questions.includes("emergencyContact") && (
      <Textbox
      required={true}
      name={buildInputName("emergencyContact")}
      label="Emergency Contact Name"
    />
    )}
    {questions.includes("emergencyContactNo") && (
      <PhoneInput
        required={true}
        name={buildInputName("emergencyContactNo")}
        handleChange={onChange}
        label="Emergency Contact number"
      />
    )}
    <Divider />
  </Spacer>
);

export default ContactInfo;
